(function ($) {
  'use strict';

  Drupal.behaviors.siteFooterSocialV1 = {
    attach: function (context, settings) {
      $('.js-qr-code__image', context).hover(function () {
        $('.js-footer-qrcode').toggle();
      });
    }
  };
})(jQuery);
